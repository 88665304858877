


















































































import { Group } from "@/types/group";
import { Watch } from "vue-property-decorator";
import Vue from "vue";
import Component from "vue-class-component";
import axios from "axios";
import GroupItem from "@/components/group/GroupItem.vue";
import { Dictionary } from "node_modules/vue-router/types/router";

@Component({
  components: {
    GroupItem,
  },
})
export default class GroupSearch extends Vue {
  results: Group[] = [];
  isLoading = false;
  pages = 0;
  enableWatcher = false;
  filters: Dictionary<string | (string | null)[] | null | undefined> = {
    text: "",
    page: "",
  };
  delayTimer = 0;

  mounted(): void {
    this.filters = Object.assign({}, this.filters, this.$route.query);

    this.enableWatcher = true;
  }

  get hasNoResult(): boolean {
    return !!this.filters.text && this.results.length == 0;
  }

  get params(): Dictionary<string | (string | null)[] | null | undefined> {
    return Object.entries(this.filters).reduce(
      (a: Dictionary<string | (string | null)[] | null | undefined>, [k, v]) =>
        v == null || v == "" ? a : ((a[k] = v), a),
      {}
    );
  }

  get currentPage(): number {
    let page: string = this.filters.page as string;
    return !page || page == "" ? 1 : parseInt(this.filters.page as string);
  }

  @Watch("filters", { deep: true })
  onPropertyChange(): void {
    if (!this.enableWatcher) return;

    clearTimeout(this.delayTimer);
    this.delayTimer = setTimeout(() => {
      this.isLoading = true;
      this.loadData();
    }, 500); // 0.5 sec delay
  }

  changePage(page: number): void {
    let pageStr = page.toString();
    if (this.filters.page != pageStr) {
      this.filters.page = pageStr;
    }
  }

  createGroup(): void {
    this.$router.push({ name: "GroupNew", query: { text: this.filters.text } });
  }

  loadData(): void {
    let queries = this.$route.query;

    if (JSON.stringify(queries) !== JSON.stringify(this.params)) {
      this.$router.replace({ query: this.params });
    }

    axios({
      url: process.env.VUE_APP_API_BASE_URL + "groups",
      params: this.filters,
      method: "GET",
    })
      .then((response) => {
        const groups: Group[] = response.data.data;
        this.results = groups;
        this.pages = response.data.last_page;
      })
      .catch((err) => {
        this.$toast.error(err.response.data.message);
      })
      .finally(() => {
        this.isLoading = false;
      });
  }

  @Watch("$route.query", { deep: true })
  updateFilters(): void {
    this.filters = Object.assign({}, this.filters, this.$route.query);
  }

  clear(): void {
    this.enableWatcher = false;
    this.results = [];
    this.filters = {
      text: "",
      page: "",
    };
    this.pages = 0;
    this.enableWatcher = true;
  }
}
